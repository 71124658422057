/* ------------------------------------------------------------ *\
	Widget
\* ------------------------------------------------------------ */

.widget { margin-bottom: 48px; 

	.widget-title { font-size: 16px; font-weight: 900; text-transform: uppercase; letter-spacing: 0.05em; }
	.widget-title:after { content: ''; display: block; width: 40px; height: 1px; margin: 16px 0 30px; background: $color-orange; }
}

/* ------------------------------------------------------------ *\
	Widget Archive
\* ------------------------------------------------------------ */

.widget_archive {

	ul { margin: -5px 0 0; list-style: none outside none;

		li { margin-bottom: 22px; font-size: 13px; font-weight: bold; text-transform: uppercase; letter-spacing: 0.05em; }
		a { color: $color-grey; }
		a:hover { color: $color-orange; }
		a:before { content: '\f105'; display: inline-block; vertical-align: top; margin: -1px 10px -1px 0; font-family: FontAwesome; font-size: 14px; font-weight: normal; }
	}
}

/* ------------------------------------------------------------ *\
	Widget Categories
\* ------------------------------------------------------------ */

.widget_categories {

	ul { margin: -5px 0 0; list-style: none outside none;

		li { margin-bottom: 22px; font-size: 13px; font-weight: bold; text-transform: uppercase; letter-spacing: 0.05em; }
		a { color: $color-grey; }
		a:hover { color: $color-orange; }
		a:before { content: '\f105'; display: inline-block; vertical-align: top; margin: -1px 10px -1px 0; font-family: FontAwesome; font-size: 14px; font-weight: normal; }
	}
}

/* ------------------------------------------------------------ *\
	Widget Tagcloud
\* ------------------------------------------------------------ */

.widget_tag_cloud {

	.tagcloud { font-size: 0; margin-bottom: -8px; 

		a { display: inline-block; vertical-align: top; padding: 5px 11px; margin: 0 9px 14px 0; border: 1px solid $color-dark; color: $color-dark; font-size: 13px; font-weight: bold; text-transform: uppercase; letter-spacing: 0.025em; }
		a {
			-webkit-transition: all .2s ease-out; 
			     -o-transition: all .2s ease-out; 
			        transition: all .2s ease-out; 
		}
		a:hover { background: $color-orange; border-color: $color-orange; color: #fff; }
	}
}

/* ------------------------------------------------------------ *\
	Widget Gallery
\* ------------------------------------------------------------ */

.widget_gallery {

	ul { list-style: none outside none; text-align: center; 
		
		div { position: relative; }
		div:after { content: ''; position: absolute; left: 0; top: 0; width: 100%; height: 100%; background: rgba(250, 111, 28, 0); }
		div:after {
			-webkit-transition: background .2s ease-out; 
			     -o-transition: background .2s ease-out; 
			        transition: background .2s ease-out; 
		}
		div:hover:after { background: rgba(250, 111, 28, .83); }
		div a { position: absolute; z-index: 10; left: 50%; top: 50%; color: #fff; font-size: 20px; visibility: hidden; opacity: 0; }
		div a {
			-webkit-transition: all .2s ease-out; 
			     -o-transition: all .2s ease-out; 
			        transition: all .2s ease-out; 
			-webkit-transform: translate(-50%, 0%); 
			    -ms-transform: translate(-50%, 0%); 
			     -o-transform: translate(-50%, 0%); 
			        transform: translate(-50%, 0%); 
		}
		div:hover a { opacity: 1; visibility: visible; }
		div:hover a {
			-webkit-transform: translate(-50%, -50%); 
			    -ms-transform: translate(-50%, -50%); 
			     -o-transform: translate(-50%, -50%); 
			        transform: translate(-50%, -50%); 
		}
	}
}

/* ------------------------------------------------------------ *\
	Widget Text
\* ------------------------------------------------------------ */

.widget_text {

	.widget-body { margin: -6px 0 0; }
	.widget-body p { margin-bottom: 25px; letter-spacing: 0.025em;  }
}
