/* ------------------------------------------------------------ *\
	Content
\* ------------------------------------------------------------ */

.content { padding: 61px 0 28px; 

	/*  Mobile  */
	
	@media (max-width: $breakpoint-mobile) {
		& { padding: 35px 0; }
	}
}
