/*
Theme Name: Charity
Author: Victor Tihai
Author URI: https://wplook.com/
Description: Premium HTML template for Charity, NGO, Foundation.
Version: 1.0.0
*/

/* TABLE OF CONTENT
- [ Variables;
- [ Settings;
- [ Foundation;
- [ Font Awesome;
- [ Animations;
- [ OWL Carousel;
- [ Bar;
- [ Header;
- [ Sections;
- [ Footer;
- [ Main;
- [ Sidebar;
- [ Content;
- [ Buttons;
- [ Navigation;
- [ Sllider;
- [ Progress Bar;
- [ Latest News;
- [ Events;
- [ Projects;
- [ Staff;
- [ Callout;
- [ List;
- [ Subscribe;
- [ Breadcrumbs;
- [ Widget;
- [ Post;
- [ Pagination;
- [ Socials;
- [ Comments;
- [ Cause;
- [ Tabs;
- [ Form;
- [ Error;
*/

// Variables
@import 'variables';

// Settings
@import 'settings';


// Foundation
@import '../../bower_components/foundation/scss/foundation';
// Font Awesome
@import '../../bower_components/font-awesome/scss/font-awesome';
// Animate CSS
@import '../../bower_components/animate.css/animate';
// OWL Carousel
@import '../../vendor/css/owl.carousel.scss';
@import '../../vendor/css/number.scss';

// Regions
@import 'regions/bar';
@import 'regions/header';
@import 'regions/section';
@import 'regions/footer';
@import 'regions/main';
@import 'regions/sidebar';
@import 'regions/content';
 
// Modules
@import 'modules/button';
@import 'modules/navigation';
@import 'modules/slider';
@import 'modules/progressbar';
@import 'modules/latest-news';
@import 'modules/event';
@import 'modules/project';
@import 'modules/staff';
@import 'modules/callout';
@import 'modules/list';
@import 'modules/subscribe';
@import 'modules/breadcrumbs';
@import 'modules/widget';
@import 'modules/post';
@import 'modules/pagination';
@import 'modules/socials';
@import 'modules/comments';
@import 'modules/cause';
@import 'modules/tabs';
@import 'modules/form';
@import 'modules/error';
