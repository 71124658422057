/* ------------------------------------------------------------ *\
	Progressbar
\* ------------------------------------------------------------ */

.progressbar { height: 3px; background: #e3e1ee; 

	.progressbar-inner { position: relative; height: 3px; width: 0; background: $color-dark; }
	.progressbar-inner {
		-webkit-transition: width 1s ease-out; 
		     -o-transition: width 1s ease-out; 
		        transition: width 1s ease-out; 
	}
	span { position: absolute; right: 0; bottom: 100%; margin-bottom: 10px; padding: 2px 10px; background: $color-orange; color: #fff; font-size: 12px; letter-spacing: 0.1em; font-weight: bold; }
	span {
		-webkit-transform: translateX(50%); 
		    -ms-transform: translateX(50%); 
		     -o-transform: translateX(50%); 
		        transform: translateX(50%); 
	}
	span:after { content: ''; position: absolute; top: 100%; left: 50%; margin-left: -5px; border-width: 5px 5px 0; border-style: solid; border-color: $color-orange transparent; }

	&.progressbar-alt { height: 11px; 
		.progressbar-inner { height: 11px; }
	}
}
