/* ------------------------------------------------------------ *\
	Project
\* ------------------------------------------------------------ */

.project { padding-bottom: 6px; border-bottom: 1px solid #dcdcdc;

	& + .project { margin-top: 34px; }

	.project-image { position: relative; margin-bottom: 23px; }
	.project-image img { width: 100%; }

	.project-overlay { position: absolute; left: 0; top: 0; width: 100%; height: 100%; }
	.project-overlay {
		-webkit-transition: background .2s ease-out; 
		     -o-transition: background .2s ease-out; 
		        transition: background .2s ease-out; 
	}
	.project-overlay:hover { background: rgba(250, 111, 28, .83); }
	.project-overlay a { position: absolute; left: 50%; top: 50%; color: #fff; font-size: 24px; visibility: hidden; opacity: 0; }
	.project-overlay a {
		-webkit-transition: all .2s ease-out; 
		     -o-transition: all .2s ease-out; 
		        transition: all .2s ease-out; 
		-webkit-transform: translate(-50%, 0%); 
		    -ms-transform: translate(-50%, 0%); 
		     -o-transform: translate(-50%, 0%); 
		        transform: translate(-50%, 0%); 
	}
	.project-overlay:hover a {
		opacity: 1;
		visibility: visible;
		-webkit-transform: translate(-50%, -50%); 
		    -ms-transform: translate(-50%, -50%); 
		     -o-transform: translate(-50%, -50%); 
		        transform: translate(-50%, -50%); 
	}

	.project-content h4 { margin-bottom: 10px; font-size: 13px; text-transform: uppercase; font-weight: 900; letter-spacing: 0.05em; line-height: 1.9; }
	.project-content a { color: $color-dark; }
	.project-content a:hover { color: $color-orange; }
	.project-content p { letter-spacing: 0.025em; }
}
