/* ------------------------------------------------------------ *\
	List Links
\* ------------------------------------------------------------ */

.list-links { float: left; min-width: 120px; list-style: none outside none; margin: 0; list-style: none outside none; text-transform: uppercase; font-size: 14px; letter-spacing: 0.025em; 
	
	li { padding: 6px 0; }
	a { color: #7d7b87; }
	a:hover { color: #fff; }
}

/* ------------------------------------------------------------ *\
	List Posts
\* ------------------------------------------------------------ */

.list-popular-posts { margin: 0; list-style: none outside none; 

	li { overflow: hidden; 

		& + li { margin-top: 34px; }

		span { position: relative; float: left; width: 70px; margin-right: 17px; }
		span:after { content: ''; position: absolute; left: 0; top: 0; width: 100%; height: 100%; background: rgba(250, 111, 28, 0); }
		span:after {
			-webkit-transition: background .2s ease-out; 
			     -o-transition: background .2s ease-out; 
			        transition: background .2s ease-out; 
		}
		span:hover:after { background: rgba(250, 111, 28, .83); }
		span a { position: absolute; z-index: 10; left: 50%; top: 50%; color: #fff; font-size: 20px; visibility: hidden; opacity: 0; }
		span a {
			-webkit-transition: all .2s ease-out; 
			     -o-transition: all .2s ease-out; 
			        transition: all .2s ease-out; 
			-webkit-transform: translate(-50%, 0%); 
			    -ms-transform: translate(-50%, 0%); 
			     -o-transform: translate(-50%, 0%); 
			        transform: translate(-50%, 0%); 
		}
		span:hover a { opacity: 1; visibility: visible; }
		span:hover a {
			-webkit-transform: translate(-50%, -50%); 
			    -ms-transform: translate(-50%, -50%); 
			     -o-transform: translate(-50%, -50%); 
			        transform: translate(-50%, -50%); 
		}

		div { float: none; overflow: hidden; }

		h4 { margin: 0 0 5px; text-transform: uppercase; font-weight: bold; font-size: 13px; line-height: 1.6; letter-spacing: 0.05em; }
		h4 a { color: $color-dark; }
		h4 a:hover { color: $color-orange; }
		p { margin: 0; font-size: 12px; color: #52505b; letter-spacing: 0.025em; }
	}
}

/* ------------------------------------------------------------ *\
	List Radios
\* ------------------------------------------------------------ */

.list-radios { display: inline-block; vertical-align: middle; margin: -2px 0 0; list-style: none outside none; 

	li { display: inline-block; vertical-align: middle; margin-right: 25px; }
	input { display: none; }
	input + label { margin: 0; font-size: 14px; color: $color-dark; }
	label:before { content: ''; display: inline-block; vertical-align: middle; width: 15px; height: 15px; margin: -2px 5px 0 0; border-radius: 50%; border: 1px solid #b5b5b5; }
	input:checked ~ label:before { box-shadow: inset 0 0 0 3px #fff, inset 0 0 0 10px $color-orange; }

	/*  Mobile  */
	
	@media (max-width: $breakpoint-mobile) {
		& { display: block; padding: 10px 0; }
		li { display: block; }
	}
}
