/* ------------------------------------------------------------ *\
	Navigation
\* ------------------------------------------------------------ */

.nav { position: relative; z-index: 200; float: left; 

	ul { list-style: none outside none; }
	
	> ul { float: left; margin: 0; font-size: 14px; font-weight: bold; text-transform: uppercase; 

		> li { position: relative; float: left; margin: 0 13px 0 14px; padding: 5px 0 30px; 

			> span { display: none; }

			> a { display: block; padding: 5px 0; color: #27262c; }
			> a {
				-webkit-transition: color .2s ease-out; 
				     -o-transition: color .2s ease-out; 
				        transition: color .2s ease-out; 
			}
			> a:hover,
			&.current-menu-item > a { color: $color-orange; }

			> ul { position: absolute; left: 0; top: 100%; width: 240px; margin: 0; padding: 26px 0 30px; background: $color-dark; visibility: hidden; opacity: 0;

				& {
					-webkit-transition: all .2s ease-out; 
					     -o-transition: all .2s ease-out; 
					        transition: all .2s ease-out; 
				}
				
				> li {

					& + li { margin-top: 17px; }
					> a { display: block; padding: 4px 28px; color: #fff; border-right: 3px solid transparent; }
					> a {
						-webkit-transition: border .2s ease-out; 
						     -o-transition: border .2s ease-out; 
						        transition: border .2s ease-out; 
					}
					> a:hover,
					&.current-menu-item > a { border-right: 3px solid $color-orange; }
				}
			}
			&:hover > ul { visibility: visible; opacity: 1; }
		}
	}

	/*  Small Desktop  */

	@media (max-width: $breakpoint-small-desktop) {
		& {
			> ul { font-size: 13px; 

				> li { margin: 0 10px; height: 69px; 

					> ul { font-size: 13px; }
				}
			}
		}
	}

	/*  Tablet Portrait  */
	
	@media (max-width: $breakpoint-tablet-portrait) {
		& { display: none; position: absolute; left: 0; top: 100%; width: 100%; background: #fff; 

			> ul { float: none; 

				> li { float: none; margin: 0; height: auto; padding: 0; border-top: 1px solid #f0eeee;
					
					> span { display: block; position: absolute; right: 0; top: 0; line-height: 38px; width: 38px; text-align: center; border-left: 1px solid #f0eeee; }
					> a { display: block; padding: 8px 15px; }
					> ul { display: none; position: static; width: auto; visibility: visible; opacity: 1; padding: 0;

						& {
							-webkit-transition: none; 
							     -o-transition: none; 
							        transition: none; 
						}
						> li + li { margin-top: 0; }
						> li > a { padding: 10px 25px; }
					}
				}
			}
		}
	}
}


