/* ------------------------------------------------------------ *\
	Variables
\* ------------------------------------------------------------ */

$color-orange: #fa6f1c;
$color-dark: #27262c;
$color-grey: #75737f;


$breakpoint-small-desktop: 1199px;
$breakpoint-tablet-portrait: 1023px;
$breakpoint-mobile: 767px;
