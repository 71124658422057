/* ------------------------------------------------------------ *\
	Subscribe
\* ------------------------------------------------------------ */

.subscribe { padding: 14px 0; 
	
	.subscribe-btn { float: right; width: 50px; height: 40px; background: $color-orange; color: #fff; border: 0; cursor: pointer; }
	.subscribe-btn:hover { background: #de6218; }
	.subscribe-inner { overflow: hidden; }

	.subscribe-field { margin: 0; height: 40px; font-size: 14px; padding: 0 18px; border: 0; }

	.subscribe-field::-webkit-input-placeholder { color: #7d7b87; }
	.subscribe-field:-moz-placeholder { color: #7d7b87; }
	.subscribe-field::-moz-placeholder { color: #7d7b87; }
	.subscribe-field:-ms-input-placeholder { color: #7d7b87; }

}