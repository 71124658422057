/* ------------------------------------------------------------ *\
	Bar
\* ------------------------------------------------------------ */

.bar { background: #fbfbfb; border-bottom: 1px solid #edebf5; color: #7b7983; 

	i { font-size: 18px; }

	.bar-phone { float: left; margin: 0; font-size: 14px; line-height: 40px; padding-top: 4px; color: #27262c;
		i { vertical-align: middle; margin: 0 7px 0 0; color: #5b5965; }
		a { color: $color-dark;}
	}

	.bar-socials { float: right;

		ul { display: inline-block; vertical-align: top; margin: 0; list-style: none outside none; overflow: hidden; }
		li { float: left; line-height: 40px; }
		a { display: block; padding-top: 4px; width: 50px; border-left: 1px solid #edebf5; text-align: center; color: #7b7983; }
		a {
			-webkit-transition: background .3s ease-out; 
			     -o-transition: background .3s ease-out; 
			        transition: background .3s ease-out; 
		}
		a:hover { background: #edebf5; }
	}

	/*  Mobile  */
	
	@media (max-width: $breakpoint-mobile) {
		& {
			.bar-phone { float: none; padding: 9px 0; text-align: center; line-height: 1; }

			.bar-socials { float: none; margin: 0 -15px; list-style: none outside none; text-align: center;

				li { line-height: 30px; }
				a { width: 30px; padding-top: 0; border: 0; }
			}
		}
	}

}
