/* ------------------------------------------------------------ *\
	Button
\* ------------------------------------------------------------ */

.button { padding: 11px 15px 12px; background: transparent; border: 2px solid $color-orange; font-size: 14px; font-weight: bold; text-transform: uppercase; color: $color-orange;

	&:hover,
	&:focus { background: $color-orange; color: #fff; }

	&.button-white { border-color: #fff; color: #fff; }

	&.button-orange { background: $color-orange; color: #fff; }
	&.button-orange:hover { background: #fff; color: $color-orange; }
	
	&.button-facebook { padding: 8px 15px 7px; background: #3a559f; color: #fff; border-color: #3a559f; font-size: 13px; }
	&.button-facebook .fa { display: inline-block; vertical-align: middle; margin-right: 12px; font-size: 18px; }
	&.button-facebook:hover { background: #fff; color: #3a559f; }
}

/* ------------------------------------------------------------ *\
	Button Menu
\* ------------------------------------------------------------ */

.button-burger { display: none; position: absolute; right: 20px; top: 40px; height: 9px; padding-top: 9px;

	span { position: relative; display: block; width: 25px; height: 15px; border-top: 3px solid $color-dark; }

	span:before,
	span:after { position: absolute; width: 100%; height: 3px; background: $color-dark; content: ''; }

	span:before { top: -9px; }
	span:after { top: 3px; }

	span:before,
	span:after,
	span { 
		-webkit-transition: all .3s linear; 
				transition: all .3s linear; 
	}

	&.active span { border-color: transparent; }
	&.active span:before,
	&.active span:after { background: $color-orange; }

	&.active span:before { 
	-webkit-transform: rotate(45deg) translate(2.5px, 6px);
			transform: rotate(45deg) translate(2.5px, 6px);

	}
	&.active span:after { 
	-webkit-transform: rotate(-45deg) translate(2.5px, -6px);
			transform: rotate(-45deg) translate(2.5px, -6px); 
	}

	/*  Tablet Portrait  */

	@media (max-width: $breakpoint-tablet-portrait) {
		& { display: block; }
	}
}
