/* ------------------------------------------------------------ *\
	Tabs
\* ------------------------------------------------------------ */

.tabs-wrapper { background: #fff;
	
	.tabs { margin: 0; list-style: none outside none; border: 1px solid #ececec;  

		.tab-title { float: left; font-weight: 900; text-transform: uppercase; letter-spacing: 0.05em; line-height: 52px;
			& + .tab-title { border-left: 1px solid #ececec; }
			
			a { display: block; padding: 0 30px; color: $color-dark; font-size: 13px; outline: none; background: #fff; }
			&.active a { margin-bottom: -1px; border-bottom: 1px solid #fff; }
			.fa { font-size: 16px; color: $color-orange; margin-right: 7px;}
			.fa-paypal { color: #00457C; }
			.fa-money { color: #27262c; }
			.fa-cc-visa { color: #2C21A6;}
		}
	}
	 
	.tabs-content {
		.content { padding: 19px 30px 35px; border-width: 0 1px 1px; border-style: solid; border-color: #ececec; }
	}

	/*  Mobile  */
	
	@media (max-width: $breakpoint-mobile) {
		.tabs {
			.tab-title + .tab-title { border-left: 0; border-top: 1px solid #ececec; }
			.tab-title { float: none; line-height: 40px; 

				a { padding: 0 15px; }
				&.active a { background: #fafafa; }
			}
		}

		.tabs-content {
			.content { padding: 15px; }
		}
	}
}
