/* ------------------------------------------------------------ *\
	Comments
\* ------------------------------------------------------------ */

.comments {

	h3 { margin-bottom: 0; padding-bottom: 17px; border-bottom: 1px solid #dcdcdc; font-size: 16px; font-weight: bold; text-transform: uppercase; letter-spacing: 0.05em; }

	.commentlist { margin: 0 0 15px; list-style: none outside none; }

	.comment { padding: 30px 0 26px;
		
		& + .comment { border-top: 1px solid #ebebeb; }

		.comment-inner { overflow: hidden; }
		.comment-image { float: left; width: 72px; margin-right: 20px; }
		
		.comment-content { overflow: hidden;
			
			h4 { margin: 0 0 11px; font-weight: bold; text-transform: uppercase; font-size: 13px; letter-spacing: 0.05em; }
			h4 a { color: $color-dark; }
			h4 a:hover { color: $color-orange; }
			p { margin-bottom: 0; letter-spacing: 0.025em; }
		}

		.comment-reply-link { padding: 3px 10px; background: $color-orange; color: #fff; text-transform: uppercase; font-size: 12px; }
		.comment-reply-link:hover { opacity: .7; }
	}

	.children { margin: 25px 0 0; border-top: 1px solid #ebebeb;
		
		.comment { padding-left: 60px; }
		.comment:last-child { padding-bottom: 0; }
	}

	.respond {

		h3 { margin-bottom: 25px; }

		.field,
		.textarea { padding-left: 0; border: 0; border-bottom: 2px solid #86848f; box-shadow: none; font-size: 14px; 

			&:focus { background: #fff; }
		}

		.field { margin-bottom: 9px; height: 52px; }
		.textarea { margin-bottom: 30px; height: 113px; }

		.field,
		.textarea {
			&::-webkit-input-placeholder { color: #75737f; }
			&:-moz-placeholder { color: #75737f; }
			&::-moz-placeholder { color: #75737f; }
			&:-ms-input-placeholder { color: #75737f; }
		}

		.button { padding: 6px 20px; font-size: 12px; }
	}

	/*  Mobile  */
	
	@media (max-width: $breakpoint-mobile) {
		.comment { padding: 15px 0; 

			.comment-image { width: 40px; margin-right: 15px; }
		}

		.children {

			.comment { padding-left: 25px; }
		}

		.respond {
			.field,
			.textarea { margin-bottom: 0; }
			.field { height: 40px; }
		}
	}
}
