/* ------------------------------------------------------------ *\
	Section Featured
\* ------------------------------------------------------------ */

.section-featured { padding: 85px 0 124px; 

	.section-head { margin-bottom: 42px; text-align: center; 
		
		.section-title { margin-bottom: 12px; text-transform: uppercase; font-weight: 900; letter-spacing: 0.05em; }
		
		.section-image { margin-bottom: 10px; }
		.section-image:before,
		.section-image:after { content: ''; display: inline-block; vertical-align: middle; width: 40px; height: 1px; margin: 0 6px;  background: $color-orange; }

		p { font-size: 14px; text-transform: uppercase; letter-spacing: 0.1em; line-height: 2.2; }
	}

	/*  Mobile  */
	
	@media (max-width: $breakpoint-mobile) {

		& { padding: 35px 0; }

		.section-head {
			.section-title { font-size: 18px; }

			p { font-size: 12px; line-height: 1.8; }
			p br { display: none; }
		}
	}
}

/* ------------------------------------------------------------ *\
	Section Sponsor
\* ------------------------------------------------------------ */

.section-sponsor { position: relative; background: $color-dark; 

	.section-inner { padding: 79px 0 65px 50px; }
	.section-head { margin-bottom: 28px;

		h4 { color: #fff; font-size: 14px; text-transform: uppercase; font-weight: bold; letter-spacing: 0.1em; }
		h2 { color: #fff; text-transform: uppercase; font-weight: bold; letter-spacing: 0.05em; }
		h2:after { content: ''; display: block; width: 40px; height: 1px; margin: 19px 0 25px; background: $color-orange; }
		p { text-transform: uppercase; letter-spacing: 0.1em; line-height: 2.2; }
	}

	.section-body { margin-bottom: 43px; }
	.section-body p { line-height: 1.95; }
	.section-foot {
		
		.button { width: 172px;  }

		.section-foot-separator { display: inline-block; vertical-align: middle; width: 70px; text-transform: uppercase; font-size: 12px; font-weight: bold; color: #fff; text-align: center; }
		.section-foot-contacts { display: inline-block; vertical-align: middle;
			
			span { display: block; margin-bottom: 1px; font-size: 12px; font-weight: bold; color: #fff; text-transform: uppercase; }
			a { color: $color-orange; font-weight: bold; text-transform: uppercase; }
			a:hover { color: #fff; }
		}
	}

	/*  Tablet Portrait  */
	
	@media (max-width: $breakpoint-tablet-portrait) {
		.section-inner { padding: 35px 0; }
	}

	/*  Mobile  */
	
	@media (max-width: $breakpoint-mobile) {
		.section-head {
			h2 { font-size: 18px; }
			p { font-size: 12px; line-height: 1.8; }
		}
	}
}

/* ------------------------------------------------------------ *\
	Section Updates
\* ------------------------------------------------------------ */

.section-updates { padding: 94px 0 31px; 

	/*  Mobile  */
	
	@media (max-width: $breakpoint-mobile) {
		& { padding: 35px 0; }
	}
}

/* ------------------------------------------------------------ *\
	Section News
\* ------------------------------------------------------------ */

.section-news {

	.section-title { font-size: 18px; font-weight: 900; text-transform: uppercase; letter-spacing: 0.05em; }
	.section-title:after { content: ''; display: block; width: 40px; height: 1px; margin: 16px 0 35px; background: $color-orange; }

	.section-foot {	padding: 11px 0; 

		.section-foot-title { margin: 3px 45px 30px 0; font-size: 15px; font-weight: 900; text-transform: uppercase; overflow: hidden; }
		.section-foot-title span { float: left; margin-right: 8px; }
		.section-foot-title:after { content: ''; position: relative; top: 11px; display: block; overflow: hidden; height: 1px; background: #dddbe8; }
	}

	/*  Mobile  */
	
	@media (max-width: $breakpoint-mobile) {
		.section-foot {
			.section-foot-title { margin-right: 0; }
		}
	}
}

/* ------------------------------------------------------------ *\
	Section Upcoming Event
\* ------------------------------------------------------------ */

.section-upcoming-event { position: relative; padding-left: 40px; 

	&:before { content: ''; position: absolute; left: -15px; top: -15px; bottom: 0; width: 1px; background: #dddbe8; }
	.section-head { overflow: hidden; }

	.section-head-actions { float: right; }
	.section-head-actions .button { margin: 0; }

	.section-title { margin-bottom: 0; float: left; font-size: 18px; font-weight: 900; text-transform: uppercase; letter-spacing: 0.05em; }
	.section-title:after { content: ''; display: block; width: 40px; height: 1px; margin: 16px 0 35px; background: $color-orange; }

	/*  Tablet Portrait  */
	
	@media (max-width: $breakpoint-tablet-portrait) {
		& { padding-left: 0; }
	}

	/*  Mobile  */
	
	@media (max-width: $breakpoint-mobile) {
		.section-head { margin-bottom: 15px; }
		.section-head-actions { float: none; margin-top: -15px; }
		.section-title { float: none; }
	}
}

/* ------------------------------------------------------------ *\
	Section Staff
\* ------------------------------------------------------------ */

.section-our-staff {

	.section-head { padding: 50px 0 3px; border-top: 1px solid #dddbe8; }
	.section-title { font-size: 18px; font-weight: 900; text-transform: uppercase; letter-spacing: 0.05em; }
	.section-title:after { content: ''; display: block; width: 40px; height: 1px; margin: 17px 0 35px; background: $color-orange; }
}

/* ------------------------------------------------------------ *\
	Section Testimonials
\* ------------------------------------------------------------ */

.section-testimonials { padding: 90px 0 0; 

	.section-head { margin-bottom: 28px; text-align: center; 
		
		.section-title { margin-bottom: 12px; text-transform: uppercase; font-weight: 900; letter-spacing: 0.05em; }
		
		.section-image { margin-bottom: 10px; }
		.section-image:before,
		.section-image:after { content: ''; display: inline-block; vertical-align: middle; width: 40px; height: 1px; margin: 0 6px;  background: $color-orange; }

		p { font-size: 14px; text-transform: uppercase; letter-spacing: 0.1em; line-height: 2.2; }
	}

	/*  Mobile  */
	
	@media (max-width: $breakpoint-mobile) {
		& { padding: 35px 0 0; }
		
		.section-head {
			.section-title { font-size: 18px; }

			p { font-size: 12px; }
		}
	}
}

/* ------------------------------------------------------------ *\
	Section Events
\* ------------------------------------------------------------ */

.section-events { padding: 68px 0 5px; }

/* ------------------------------------------------------------ *\
	Section Staff
\* ------------------------------------------------------------ */

.section-staff { padding: 71px 0 70px; 

	/*  Mobile  */
	
	@media (max-width: $breakpoint-mobile) {
		& { padding: 35px 0; }
	}
}
