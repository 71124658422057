/* ------------------------------------------------------------ *\
	Callout
\* ------------------------------------------------------------ */

.callout { padding: 43px 0; background: #000;
	
	.callout-title { font-weight: bold; color: #fff; text-transform: uppercase; letter-spacing: 0.05em; line-height: 1.5; }

	.callout-actions { padding: 18px 0; font-size: 0; text-align: right; }
	.callout-actions .button { display: inline-block; vertical-align: top; min-width: 213px; margin: 0; }

	.callout-separator { color: #fff; font-weight: bold; text-transform: uppercase; }
	.callout-separator span { display: inline-block; vertical-align: middle; width: 43px; height: 43px; border-radius: 50%; background: #54525e; text-align: center; line-height: 43px; font-size: 12px; }
	.callout-separator:before,
	.callout-separator:after { content: ''; display: inline-block; vertical-align: middle; width: 28px; height: 1px; background: #54525e; }

	/*  Small Desktop  */
	
	@media (max-width: $breakpoint-small-desktop) {
		.columns { width: 100%; }

		.callout-title { text-align: center; }
		.callout-actions { text-align: center; }
	}

	/*  Mobile  */
	
	@media (max-width: $breakpoint-mobile) {
		.callout-separator { display: block; padding: 10px 0; text-align: center; }
		.callout-separator:before,
		.callout-separator:after { display: none; }
	}
}
