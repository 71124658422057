/* ------------------------------------------------------------ *\
	Pagination
\* ------------------------------------------------------------ */

ul.pagination { margin: 13px 0 0; padding: 50px 0; border-top: 1px solid #eaeaea; 

	&.pagination-alt { margin: 0; border: 0; }

	li { margin: 0 5px; 

		a { width: 41px; height: 38px; line-height: 36px; padding: 0; border: 1px solid $color-dark; border-radius: 0; font-size: 16px; font-weight: bold; text-align: center; color: $color-dark; }
		a:hover { background: $color-orange; border-color: $color-orange; color: #fff; }
	}
}
