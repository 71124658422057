/* ------------------------------------------------------------ *\
	Latest News
\* ------------------------------------------------------------ */

.latest-news-items { list-style: none outside none; 

	.latest-news-item { overflow: hidden; margin-bottom: 10px; padding-right: 25px; 

		.latest-news-item-image { float: left; width: 80px; margin-right: 20px; }
		.latest-news-item-content { overflow: hidden;

			h4 { font-size: 14px; text-transform: uppercase; font-weight: bold; letter-spacing: 0.05em; }
			h4 a { color: $color-dark; }
			h4 a:hover { color: $color-orange; }
			p { margin: 0; }
		}
	}
}