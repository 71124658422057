/* ------------------------------------------------------------ *\
	Slider Intro
\* ------------------------------------------------------------ */

.slider-intro { position: relative; clear: both; 

	.slider-actions { position: absolute; z-index: 200; left: 0; top: 50%; width: 100%; }
	.owl-prev,
	.owl-next { position: absolute; top: -17px; width: 40px; cursor: pointer; font-size: 24px; color: #fff; text-align: center; }
	.owl-prev,
	.owl-next {
		-webkit-transition: color .2s ease-out; 
		     -o-transition: color .2s ease-out; 
		        transition: color .2s ease-out; 
	}
	.owl-prev:hover,
	.owl-next:hover { color: $color-orange; }
	.owl-prev { left: 30px; }
	.owl-next { right: 30px; }

	.slides { list-style: none outside none; }

	.slide { position: relative; height: 780px; 
		
		.slide-image { position: absolute; left: 0; top: 0; width: 100%; height: 100%; }

		.slide-content { position: absolute; left: 0; bottom: 30px; width: 100%; background: $color-orange; color: #fff; }
		.slide-content p { margin-right: 40px; text-transform: uppercase; line-height: 2; letter-spacing: 0.05em; }
		.slide-title { margin: 44px 0 18px; color: #fff; text-transform: uppercase; font-weight: bold; letter-spacing: 0.1em; }

		.slide-aside { position: relative; color: #963e09; background: #fc8f30; }
		.slide-tag { position: absolute; left: 50%; padding: 9px 22px; background: #fff; top: -19px; color: $color-dark; font-size: 12px; text-transform: uppercase; font-weight: bold; letter-spacing: 0.1em; white-space: nowrap; }
		.slide-tag {
			-webkit-transform: translateX(-50%); 
			    -ms-transform: translateX(-50%); 
			     -o-transform: translateX(-50%); 
			        transform: translateX(-50%); 
		}

		.slide-progress { overflow: hidden; border-bottom: 1px solid #e5832d; line-height: 1; }
		.slide-progress span { display: block; margin-bottom: 5px; letter-spacing: 0.1em; }
		.slide-progress strong { display: block; font-size: 36px; }
		.slide-raised,
		.slide-to-go { float: left; width: 50%; height: 124px; padding-top: 38px; text-align: center; font-size: 16px; text-transform: uppercase; }
		.slide-to-go { border-left: 1px solid #e5832d; }
		.slide-actions a { display: block; line-height: 70px; color: #963e09; text-transform: uppercase; font-weight: bold; text-align: center; font-size: 16px; letter-spacing: 0.1em; }
		.slide-actions a {
			-webkit-transition: background .2s ease-out; 
			     -o-transition: background .2s ease-out; 
			        transition: background .2s ease-out; 
		}
		.slide-actions a:hover { background: #e5832d; }
	}

	/* Animations */
	.active { 
		.slide {
			.slide-content {
				-webkit-animation-name: fadeInUp;
				-moz-animation-name: fadeInUp;
				-o-animation-name: fadeInUp;
				animation-name: fadeInUp;

				animation-delay: 0.3s; /* W3C and Opera */
				-moz-animation-delay: 0.3s; /* Firefox */
				-webkit-animation-delay: 0.3s; /* Safari and Chrome */

				-webkit-animation-fill-mode:both;
				-moz-animation-fill-mode:both;
				-ms-animation-fill-mode:both;
				-o-animation-fill-mode:both;
				animation-fill-mode:both;

				-webkit-animation-duration:0.5s;
				-moz-animation-duration:0.5s;
				-ms-animation-duration:0.5s;
				-o-animation-duration:0.5s;
				animation-duration:0.5s;
			}
		}
	}

	.slide-alt { position: relative; height: 780px; 
		
		.slide-image { position: absolute; left: 0; top: 0; width: 100%; height: 100%; }

		.slide-content { position: absolute; left: 0; top: 50%; width: 100%; 

			& {
				-webkit-transform: translateY(-50%); 
				    -ms-transform: translateY(-50%); 
				     -o-transform: translateY(-50%); 
				        transform: translateY(-50%); 
			}

			.slide-content-inner { max-width: 500px; padding: 45px 30px 35px; background: $color-orange; color: #fff; }
			p { margin-bottom: 22px; text-transform: uppercase; }	
		}
		.slide-title { font-weight: bold; color: #fff; text-transform: uppercase; letter-spacing: 0.1em; line-height: 1.3; }
		.slide-title:after { content: ''; display: block; width: 40px; height: 1px; margin: 18px 0; background: #b75013; }
		.slide-actions .button { margin: 0; width: 175px; }
	}

	/* Animations */
	.active {
		.slide-alt {
			.slide-content {
				.slide-content-inner {
  					-webkit-animation-name: fadeInDown;
					-moz-animation-name: fadeInDown;
					-o-animation-name: fadeInDown;
					animation-name: fadeInDown;

					animation-delay: 0.3s; /* W3C and Opera */
					-moz-animation-delay: 0.3s; /* Firefox */
					-webkit-animation-delay: 0.3s; /* Safari and Chrome */

					-webkit-animation-fill-mode:both;
					-moz-animation-fill-mode:both;
					-ms-animation-fill-mode:both;
					-o-animation-fill-mode:both;
					animation-fill-mode:both;

					-webkit-animation-duration:0.5s;
					-moz-animation-duration:0.5s;
					-ms-animation-duration:0.5s;
					-o-animation-duration:0.5s;
					animation-duration:0.5s;
				}
			}
		}
	}

	/*  Small Desktop  */

	@media (max-width: $breakpoint-small-desktop) {
		& {
			.slide,
			.slide-alt { height: 600px; }
			.slide {
				.slide-title { margin-top: 40px; }
				.slide-content p { line-height: 1.5; }
			}
		}
	}

	/*  Tablet Portrait  */
	
	@media (max-width: $breakpoint-tablet-portrait) {
		.slide {
			.slide-aside { margin: 30px -15px 0; }
		}
		.slide-alt {
			.slide-content { 
				.slide-content-inner { margin: 0 auto; }
			}
		}
	}

	/*  Mobile  */
	
	@media (max-width: $breakpoint-mobile) {

		.owl-prev { left: 10px; width: auto; font-size: 18px; }
		.owl-next { right: 10px; width: auto; font-size: 18px; }

		.slide,
		.slide-alt { height: 440px; 

			.slide-title { font-size: 18px; }
			.slide-content p { font-size: 12px; }
		}

		.slide {
			.slide-content { width: auto; margin: 0 15px; }
			.slide-progress strong { font-size: 20px; }
			.slide-to-go,
			.slide-raised { height: auto; padding: 25px 0 15px; }
			.slide-actions a { line-height: 40px; font-size: 16px; }
		}
	}
}



/* ------------------------------------------------------------ *\
	Slider Featured
\* ------------------------------------------------------------ */

.slider-featured { position: relative; 

	.slider-actions { position: absolute; z-index: 200; left: 0; top: 68px; width: 100%; }
	.owl-prev,
	.owl-next { position: absolute; top: 0; width: 40px; cursor: pointer; font-size: 24px; color: $color-dark; text-align: center; }
	.owl-prev,
	.owl-next {
		-webkit-transition: color .2s ease-out; 
		     -o-transition: color .2s ease-out; 
		        transition: color .2s ease-out; 
	}
	.owl-prev:hover,
	.owl-next:hover { color: $color-orange; }
	.owl-prev { left: -72px; }
	.owl-next { right: -72px; }


	.slides { list-style: none outside none; }
	.slide { width: 270px; 
		
		.slide-image { margin-bottom: 33px; position: relative; }
		

		.slide-tag { position: absolute; right: 20px; top: 20px; padding: 3px 13px; background: #fff; text-transform: uppercase; font-size: 12px; letter-spacing: 0.025em; font-weight: bold; color: $color-dark; }

		.slide-overlay { position: absolute; left: 0; top: 0; width: 100%; height: 100%; background: rgba(39,38,44,.7); visibility: hidden; opacity: 0; }
		.slide-overlay {
			-webkit-transition: all .2s ease-out; 
			     -o-transition: all .2s ease-out; 
			        transition: all .2s ease-out; 
		}
		.slide-overlay .button { margin: 0; min-width: 154px; padding: 9px 10px; font-size: 13px; }
		.slide-overlay-inner { position: absolute; left: 50%; top: 50%; }
		.slide-overlay-inner {
			-webkit-transition: all .2s ease-out; 
			     -o-transition: all .2s ease-out; 
			        transition: all .2s ease-out; 
			-webkit-transform: translate(-50%, 0%); 
			    -ms-transform: translate(-50%, 0%); 
			     -o-transform: translate(-50%, 0%); 
			        transform: translate(-50%, 0%); 
		}

		.slide-image:hover {
			.slide-overlay { visibility: visible; opacity: 1; }
			.slide-overlay-inner {
				-webkit-transform: translate(-50%, -50%); 
				    -ms-transform: translate(-50%, -50%); 
				     -o-transform: translate(-50%, -50%); 
				        transform: translate(-50%, -50%); 
			}
		}

		.slide-content { margin-bottom: 41px; }
		.slide-content h3 { margin-bottom: 11px; font-size: 16px; font-weight: bold; text-transform: uppercase; letter-spacing: 0.05em; }
		.slide-content p { line-height: 1.75; letter-spacing: 0.025em; }

		.slide-foot { color: $color-dark; letter-spacing: 0.1em; }
		.slide-foot span { color: $color-orange; }
		.slide-foot .progressbar { margin-bottom: 10px; }
	}

	/*  Small Desktop  */

	@media (max-width: $breakpoint-small-desktop) {
		& { max-width: 870px; margin: 0 auto; }
	}

	/*  Tablet Portrait  */
	
	@media (max-width: $breakpoint-tablet-portrait) {
		& { max-width: 570px; }
	}

	/*  Mobile  */
	
	@media (max-width: $breakpoint-mobile) {
		& { max-width: 270px;
			
			.owl-prev { left: -20px; width: auto; }
			.owl-next { right: -20px; width: auto; }
		}
	}
}

/* ------------------------------------------------------------ *\
	Slider Sponsor
\* ------------------------------------------------------------ */

.slider-sponsor { position: absolute; left: 0; right: 50%; top: 0; height: 100%; 

	.slider-actions { position: absolute; z-index: 200; left: 0; top: 50%; width: 100%; }
	.owl-prev,
	.owl-next { position: absolute; top: -17px; width: 40px; cursor: pointer; font-size: 24px; color: #fff; text-align: center; }
	.owl-prev,
	.owl-next {
		-webkit-transition: color .2s ease-out; 
		     -o-transition: color .2s ease-out; 
		        transition: color .2s ease-out; 
	}
	.owl-prev:hover,
	.owl-next:hover { color: $color-orange; }
	.owl-prev { left: 30px; }
	.owl-next { right: 30px; }

	.slides { list-style: none outside none; }
	.slider-clip,
	.slides,
	.owl-stage-outer,
	.owl-stage,
	.owl-item,
	.slide,
	.slide-image { height: 100%; }

	/*  Tablet Portrait  */
	
	@media (max-width: $breakpoint-tablet-portrait) {
		& { position: relative; left: 0; right: 0; height: 350px; }
	}
}

/* ------------------------------------------------------------ *\
	Slider News
\* ------------------------------------------------------------ */

.slider-news { position: relative; margin-right: 45px; 

	.slider-actions { position: absolute; z-index: 200; right: 0; top: -80px; }
	.owl-prev,
	.owl-next { float: left; width: 41px; line-height: 36px; cursor: pointer; font-size: 18px; color: $color-dark; text-align: center; border: 1px solid $color-dark; }
	.owl-prev,
	.owl-next {
		-webkit-transition: all .2s ease-out; 
		     -o-transition: all .2s ease-out; 
		        transition: all .2s ease-out; 
	}
	.owl-prev:hover,
	.owl-next:hover { background: $color-orange; border-color: $color-orange; color: #fff; }
	.owl-next { margin-left: -1px; }

	.slides { list-style: none outside none; }
	.slide { overflow: hidden; 

		.slide-image { position: relative; float: left; width: 270px; margin-right: 25px; }
		.slide-image img { width: 100%; }

		.slide-bar { position: absolute; left: 0; bottom: 0; width: 100%; padding: 5px 0 5px 14px; background: $color-orange; color: #fff; letter-spacing: 0.025em; }
		.slide-bar span + span { display: inline-block; vertical-align: top; padding-left: 18px; }
		.slide-bar .fa { margin-right: 3px; }

		.slide-content { overflow: hidden; }
		.slide-content h4 { margin: -3px 0 18px; font-size: 16px; line-height: 1.5; text-transform: uppercase; font-weight: bold; letter-spacing: 0.05em; }
		.slide-content h4 a { color: $color-dark; }
		.slide-content h4 a:hover { color: $color-orange; }
		.slide-content p { color: #52505b; }
	}

	/*  Small Desktop  */
	
	@media (max-width: $breakpoint-small-desktop) {
		.slide {
			.slide-image { width: 40%; }
			.slide-bar span { padding-right: 10px; }
			.slide-bar span + span { padding-left: 0; }
		}
	}

	/*  Tablet Portrait  */
	
	@media (max-width: $breakpoint-tablet-portrait) {
		& { margin-right: 0; }
	}

	/*  Mobile  */
	
	@media (max-width: $breakpoint-mobile) {
		.slide {
			.slide-image { float: none; width: auto; margin: 0 0 15px; }
		}
	}
}

/* ------------------------------------------------------------ *\
	Slider Staff
\* ------------------------------------------------------------ */

.slider-staff { position: relative; 

	.slider-actions { position: absolute; z-index: 200; right: 4px; top: -78px; }
	.owl-prev,
	.owl-next { float: left; width: 41px; line-height: 36px; cursor: pointer; font-size: 18px; color: $color-dark; text-align: center; border: 1px solid $color-dark; }
	.owl-prev,
	.owl-next {
		-webkit-transition: all .2s ease-out; 
		     -o-transition: all .2s ease-out; 
		        transition: all .2s ease-out; 
	}
	.owl-prev:hover,
	.owl-next:hover { background: $color-orange; border-color: $color-orange; color: #fff; }
	.owl-prev { }
	.owl-next { margin-left: -1px; }

	.slides { list-style: none outside none; }
}

/* ------------------------------------------------------------ *\
	Slider Testimonials
\* ------------------------------------------------------------ */

.slider-testimonials { position: relative; padding: 60px 0 41px; background: $color-orange; 

	&:before { content: ''; position: absolute; left: 50%; top: 0; margin-left: -6px; border-width: 6px 6px 0; border-style: solid; border-color: #fff transparent; }

	.owl-dots { text-align: center; }
	.owl-dot { display: inline-block; vertical-align: middle; width: 12px; height: 12px; margin: 0 4px; border-radius: 50%; background: #c65410; }
	.owl-dot:hover,
	.owl-dot.active { background: #97400c; }

	.slides { margin: 0; list-style: none outside none; }
	.slide { color: #fff; text-align: center; 

		.slide-entry p { font-size: 21px; font-style: italic; letter-spacing: 0.05em; line-height: 1.6; }
		.slide-entry:after { content: ''; display: block; max-width: 170px; height: 1px; margin: 28px auto 25px; background: #de6218; }
		.slide-foot p { margin: 0 0 10px; color: #83380b; letter-spacing: 0.05em; text-transform: uppercase; font-weight: bold; }
	}

	/*  Tablet Portrait  */
	
	@media (max-width: $breakpoint-tablet-portrait) {
		.slide { padding: 0 20px; 
			
			.slide-entry p br { display: none; }
		}
	}

	/*  Mobile  */
	
	@media (max-width: $breakpoint-mobile) {
		.slide {
			.slide-entry p { font-size: 16px; }
			.slide-foot p { font-size: 14px; }
		}
	}
}

/* ------------------------------------------------------------ *\
	Slider Widgets
\* ------------------------------------------------------------ */

.slider-widget {
	.owl-dots { padding: 14px 0 0; text-align: left; }
	.owl-dot { display: inline-block; vertical-align: middle; width: 10px; height: 10px; margin: 0 6px 0 0; background: #cbcbcf; }
	.owl-dot:hover,
	.owl-dot.active { background: $color-orange; }

	.slides { list-style: none outside none; }
}
